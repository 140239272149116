<template>
    <NConfigProvider inline-theme-disabled :theme-overrides="themeOverrides">
        <NDialogProvider>
            <NuxtLayout>
                <NuxtPage />
            </NuxtLayout>
        </NDialogProvider>
    </NConfigProvider>
</template>

<script lang="ts" setup>
import { NConfigProvider, NDialogProvider } from 'naive-ui'
import type { GlobalThemeOverrides } from 'naive-ui'

const route = useRoute()

const themeOverrides = <GlobalThemeOverrides>{
    common: {
        bodyColor: 'var(--color-text)',
    },
    Button: {
        border: '1px solid var(--color-primary)',
        borderHover: '1px solid var(--color-primary)',
        borderPressed: '1px solid var(--color-primary)',
        borderFocus: '1px solid var(--color-primary)',
        borderPrimary: '1px solid var(--color-primary)',
        borderHoverPrimary: '1px solid var(--color-primary)',
        borderPressedPrimary: '1px solid var(--color-primary-focus)',
        borderFocusPrimary: '1px solid var(--color-primary)',
        borderDisabledPrimary: '1px solid var(--color-text-light)',
        borderSuccess: 'none',
        borderFocusSuccess: 'none',
        borderHoverSuccess: 'none',
        borderPressedSuccess: 'none',
        borderError: 'none',
        borderFocusError: 'none',
        borderHoverError: 'none',
        borderPressedError: 'none',
        borderRadiusLarge: 0,
        borderRadiusMedium: 0,
        borderRadiusSmall: 0,

        color: 'white',
        colorHover: 'var(--color-primary)',
        colorPressed: 'var(--color-primary-focus)',
        colorFocus: 'white',
        colorPrimary: 'var(--color-primary)',
        colorHoverPrimary: 'var(--color-primary)',
        colorPressedPrimary: 'var(--color-primary-focus)',
        colorFocusPrimary: 'var(--color-primary)',
        colorSecondary: 'var(--color-secondary-input)',

        colorSuccess: 'var(--color-secondary-input)',
        colorFocusSuccess: 'var(--color-secondary-input)',
        colorHoverSuccess: 'var(--color-secondary-input)',
        colorPressedSuccess: 'var(--color-secondary-input)',
        colorError: 'var(--color-secondary-input)',
        colorFocusError: 'var(--color-secondary-input)',
        colorHoverError: 'var(--color-secondary-input)',
        colorPressedError: 'var(--color-secondary-input)',

        colorDisabled: 'var(--color-text-light)',
        colorDisabledPrimary: 'var(--color-text-light)',

        textColor: 'var(--color-primary)',
        textColorHover: 'white',
        textColorPressed: 'white',
        textColorFocus: 'var(--color-primary)',
        textColorSuccess: 'var(--color-primary)',
        textColorFocusSuccess: 'var(--color-primary)',
        textColorHoverSuccess: 'var(--color-primary)',
        textColorPressedSuccess: 'var(--color-primary)',
        textColorError: 'var(--color-primary)',
        textColorFocusError: 'var(--color-primary)',
        textColorHoverError: 'var(--color-primary)',
        textColorPressedError: 'var(--color-primary)',

        fontSizeLarge: 'var(--text-size-bodyl)',
        fontWeight: 700,

        heightLarge: '48px',

        iconMarginLarge: '0 0 0 12px'
    },
    Input: {
        color: 'var(--color-sand-light)',
        colorFocus: 'var(--color-sand-light)',

        caretColor: 'var(--color-primary)',

        textColor: 'var(--color-text)',

        heightLarge: '48px',

        border: 'none',
        borderFocus: 'none',
        borderHover: 'none',
        borderDisabled: 'none',
        borderRadius: 0,

        boxShadowFocus: 'none',
    },
    Checkbox: {
        color: 'var(--color-sand-light)',
        colorChecked: 'var(--color-primary)',

        borderChecked: '1px solid var(--color-primary)',
        borderFocus: '1px solid rgb(224, 224, 230)',
        borderRadius: 0,

        fontSizeLarge: 'var(--text-size-bodym)',
        textColor: 'var(--color-text)',

        boxShadowFocus: 'none'
    },
    Radio: {
        color: 'var(--color-sand-light)',

        dotColorActive: 'var(--color-primary)',

        boxShadowActive: 'inset 0 0 0 1px var(--color-primary)',
        boxShadowHover: 'inset 0 0 0 1px var(--color-primary)',
        boxShadowFocus: 'inset 0 0 0 1px var(--color-primary)',

        fontSizeLarge: 'var(--text-size-bodym)',
        textColor: 'var(--color-text)',

    },
    Select: {
        peers: {
            InternalSelection: {
                color: 'var(--color-sand-light)',
                colorActive: 'var(--color-sand-light)',

                heightLarge: '48px',
                //paddingSingle: '0 20px',

                textColor: 'var(--color-text)',
                placeholderColor: 'var(--color-text)',
                caretColor: 'var(--color-primary)',

                //fontSizeLarge: '0.941rem',

                borderRadius: '0',

                border: 'none',
                borderHover: 'none',
                borderActive: 'none',
                borderFocus: 'none',

                boxShadowActive: 'none',
                boxShadowFocus: 'none',
                boxShadowHover: 'none',
            },
            InternalSelectMenu: {

                optionTextColor: 'var(--color-text)',
                optionTextColorActive: 'var(--color-primary)',

                //optionFontSizeLarge: '0.941rem',

                borderRadius: '0',
            }
        }
    },
    Form: {
        labelFontSizeTopLarge: 'var(--text-size-bodyxs)',
        labelHeightLarge: 'var(--text-size-bodyxs)',
        labelFontWeight: 800,
        labelTextColor: 'var(--color-primary)'
    },
    Pagination: {
        itemBorder: '0.5px solid var(--color-primary)',
        itemBorderActive: '0.5px solid var(--color-primary)',
        itemBorderHover: '0.5px solid var(--color-primary)',
        itemBorderPressed: '0.5px solid var(--color-primary)',
        itemBorderRadius: 0,

        itemTextColor: 'var(--color-text-light)',
        itemTextColorActive: 'var(--color-primary)',
        itemTextColorHover: 'var(--color-primary)',
        itemTextColorPressed: 'var(--color-primary)',

        itemSizeLarge: '52px',
        itemMarginLarge: '0 -0.5px 0 0',

        buttonBorder: '0.5px solid var(--color-primary)',
        buttonBorderActive: '0.5px solid var(--color-primary)',
        buttonBorderHover: '0.5px solid var(--color-primary)',
        buttonBorderPressed: '0.5px solid var(--color-primary)',
        buttonIconColor: 'var(--color-text-light)',
        buttonIconColorActive: 'var(--color-primary)',
        buttonIconColorHover: 'var(--color-primary)',
        buttonIconColorPressed: 'var(--color-primary)',

        itemBorderDisabled: '0.5px solid rgb(224, 224, 230)',
        itemColorDisabled: 'white'
    },
    Tag: {
        borderRadius: 0,

        color: 'var(--color-primary-light)',
        textColor: 'var(--color-primary)',
    },
    Tabs: {
        tabTextColorLine: 'var(--color-text-light)',
        tabTextColorActiveLine: 'var(--color-primary)',
        tabTextColorHoverLine: 'var(--color-primary)',

        barColor: 'var(--color-primary)',

        tabGapMediumLine: '40px'
    },
    Calendar: {
        cellColor: 'white',
        cellColorHover: 'white',
        borderColor: '#E2E8F0',
        barColor: 'transparent',
        dateColorCurrent: 'var(--color-primary)',
    },
    Dialog: {
        textColor: 'var(--color-text)',
        titleTextColor: 'var(--color-text)',
        closeColorHover: 'white',
        closecolorAzurePressed: 'white',
        //iconColorWarning: colorPeach,

        iconSize: '36px',
        padding: 'var(--size-gutter-double)',
        //contentMargin: '8px 0px 40px 0px'

        borderRadius: '0'
    },
}

useSeoMeta({
    formatDetection: 'telephone=no',
    themeColor: '#FFFFFF'
})

useHead({
    htmlAttrs: {
        lang: 'en-GB'
    },
    link: [
        { rel: 'preconnect dns-prefetch', href: 'https://cms.eic-federation.eu' },

        { rel: 'icon', type: 'image/png', href: '/favicon-96x96.png', sizes: '96x96' },
        { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
        { rel: 'shortcut icon', href: '/favicon.ico' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },

        { rel: 'canonical', href: `https://www.eic-federation.eu${route.path.replace(/\/$/, "")}` },
    ]
})
</script>