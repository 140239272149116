import validate from "/home/bun/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/bun/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "logged-user": () => import("/home/bun/app/app/middleware/loggedUser.ts"),
  "optional-area": () => import("/home/bun/app/app/middleware/optionalArea.ts"),
  "private-area": () => import("/home/bun/app/app/middleware/privateArea.ts"),
  "reset-password": () => import("/home/bun/app/app/middleware/resetPassword.ts")
}