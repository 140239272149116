<template>
    <div>
        <div class="hideTrigger" ref="hideTrigger"></div>
        <header :class="[{ compact: compactMenu }, { openOverlay: overlayMenu }, { white: headerWhite && !openGroups.advocacy && !openGroups.publications && !openGroups.about }]" v-scroll-lock="overlayMenu === true && windowWidth <= 1200">
            <div :class="['background', { openOverlay: overlayMenu }, { openAdvocacy: openGroups.advocacy }, { openPublications: openGroups.publications }, { openAbout: openGroups.about }]">
            </div>
            <div class="logoZone baron headerText">
                <NuxtLink class="symbol" to="/" @click="closeMenu" aria-label="EIC Homepage">
                    <svg width="252" height="68" viewBox="0 0 252 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-0.00050354 0H151.486V16.7547H-0.00050354V0Z" />
                        <path d="M-0.00050354 25.1328H151.486V41.8875H-0.00050354V25.1328Z" />
                        <path d="M-0.00050354 50.2656H151.486V67.0203H-0.00050354V50.2656Z" />
                        <path d="M160.376 0H177.828V67.0174H160.376V0Z" />
                        <path d="M251.441 21.7003C246.665 9.02218 234.428 0 220.08 0C201.573 0 186.57 15.0028 186.57 33.508C186.57 52.0145 201.573 67.0174 220.08 67.0174C234.428 67.0174 246.665 57.9952 251.441 45.3171L234.515 41.9822C231.604 46.9306 226.236 50.2627 220.08 50.2627C210.826 50.2627 203.325 42.7613 203.325 33.508C203.325 24.2561 210.826 16.7547 220.08 16.7547C226.236 16.7547 231.604 20.0868 234.515 25.0352L251.441 21.7003Z" />
                    </svg>
                </NuxtLink>
                <div class="sep"></div>
                <div class="column">
                    <span>european</span>
                    <span>international</span>
                    <span>contractors</span>
                </div>
                <div class="sep"></div>
                <div class="column italic">
                    <span>industry</span>
                    <span>federation</span>
                    <span>since 1970</span>
                </div>
            </div>
            <div class="navCont noselect">
                <nav>
                    <div :class="['sep', { openAdvocacy: openGroups.advocacy }, { openPublications: openGroups.publications }]">
                    </div>
                    <div :class="['column', { openAdvocacy: openGroups.advocacy }]">
                        <div class="pageGroup">
                            <div class="pageGroupTitle baron headerText" @click="toggleGroup('advocacy')">
                                <span>advocacy</span>
                                <svg :class="{ open: openGroups.advocacy }" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.138102 0.325821C0.317249 0.153564 0.602118 0.15915 0.774375 0.338297L3 2.70095L5.22563 0.338297C5.39788 0.15915 5.68275 0.153564 5.8619 0.325821C6.04105 0.498078 6.04663 0.782948 5.87437 0.962095L3.32438 3.6621C3.23953 3.75033 3.12241 3.8002 3 3.8002C2.87759 3.8002 2.76047 3.75033 2.67563 3.6621L0.125626 0.962095C-0.0466314 0.782948 -0.0410457 0.498078 0.138102 0.325821Z" fill="#94A3B8" />
                                </svg>
                            </div>
                            <div class="groupNavCont">
                                <NCollapseTransition :show="openGroups.advocacy">
                                    <div class="groupNav">
                                        <NuxtLink to="/advocacy/contract-conditions" @click="closeMenu">Contract
                                            Conditions</NuxtLink>
                                        <NuxtLink to="/advocacy/public-procurement" @click="closeMenu">Public
                                            Procurement</NuxtLink>
                                        <NuxtLink to="/advocacy/eu-development-policy" @click="closeMenu">EU Development
                                            Policy</NuxtLink>
                                        <NuxtLink to="/advocacy/eu-competition-policy" @click="closeMenu">EU Competition
                                            Policy</NuxtLink>
                                        <NuxtLink to="/advocacy/sustainability" @click="closeMenu">Sustainability
                                        </NuxtLink>
                                        <NuxtLink to="/advocacy/finance" @click="closeMenu">Finance</NuxtLink>
                                    </div>
                                </NCollapseTransition>
                            </div>
                        </div>
                        <NuxtLink to="/news" class="baron headerText" @click="closeMenu">news</NuxtLink>
                        <div class="pageGroup">
                            <div class="pageGroupTitle baron headerText" @click="toggleGroup('publications')">
                                <span>publications</span>
                                <svg :class="{ open: openGroups.publications }" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.138102 0.325821C0.317249 0.153564 0.602118 0.15915 0.774375 0.338297L3 2.70095L5.22563 0.338297C5.39788 0.15915 5.68275 0.153564 5.8619 0.325821C6.04105 0.498078 6.04663 0.782948 5.87437 0.962095L3.32438 3.6621C3.23953 3.75033 3.12241 3.8002 3 3.8002C2.87759 3.8002 2.76047 3.75033 2.67563 3.6621L0.125626 0.962095C-0.0466314 0.782948 -0.0410457 0.498078 0.138102 0.325821Z" fill="#94A3B8" />
                                </svg>
                            </div>
                            <div class="groupNavCont">
                                <NCollapseTransition :show="openGroups.publications">
                                    <div class="groupNav">
                                        <NuxtLink to="/publications/position-papers" @click="closeMenu">Position Papers
                                        </NuxtLink>
                                        <NuxtLink to="/publications/bookshop" @click="closeMenu">Bookshop</NuxtLink>
                                        <NuxtLink to="/publications/annual-reports" @click="closeMenu">Annual Reports
                                        </NuxtLink>
                                        <NuxtLink to="/publications/statistics" @click="closeMenu">Statistics</NuxtLink>
                                    </div>
                                </NCollapseTransition>
                            </div>
                        </div>
                    </div>
                    <div :class="['sep', { openAbout: openGroups.about }]"></div>
                    <div :class="['column', { openAbout: openGroups.about }]">
                        <NuxtLink to="/conferences" class="baron headerText" @click="closeMenu">conferences</NuxtLink>
                        <div class="pageGroup">
                            <div class="pageGroupTitle baron headerText" @click="toggleGroup('about')">
                                <span>about</span>
                                <svg :class="{ open: openGroups.about }" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.138102 0.325821C0.317249 0.153564 0.602118 0.15915 0.774375 0.338297L3 2.70095L5.22563 0.338297C5.39788 0.15915 5.68275 0.153564 5.8619 0.325821C6.04105 0.498078 6.04663 0.782948 5.87437 0.962095L3.32438 3.6621C3.23953 3.75033 3.12241 3.8002 3 3.8002C2.87759 3.8002 2.76047 3.75033 2.67563 3.6621L0.125626 0.962095C-0.0466314 0.782948 -0.0410457 0.498078 0.138102 0.325821Z" fill="#94A3B8" />
                                </svg>
                            </div>
                            <div class="groupNavCont">
                                <NCollapseTransition :show="openGroups.about">
                                    <div class="groupNav">
                                        <NuxtLink to="/about/who-we-are" @click="closeMenu">Who We Are</NuxtLink>
                                        <NuxtLink to="/about/history" @click="closeMenu">History</NuxtLink>
                                        <NuxtLink to="/about/partners" @click="closeMenu">Partners</NuxtLink>
                                    </div>
                                </NCollapseTransition>
                            </div>
                        </div>
                        <NuxtLink to="/contacts" class="baron headerText" @click="closeMenu">contacts</NuxtLink>
                    </div>
                </nav>
                <div class="rightMenu baron headerText">
                    <div class="rightMenuLink">
                        <NuxtLink to="/search" @click="closeMenu">Search</NuxtLink>
                    </div>
                    <div class="rightMenuLink">
                        <NuxtLink to="/members" @click="closeMenu">{{ user ? `${user.first_name} ${user.last_name}` : 'Members' }}
                        </NuxtLink>
                    </div>
                    <div class="hamburgerCont" @click="openFullMenu">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script lang="ts" setup>
import { NCollapseTransition } from 'naive-ui';
const { getHomepage } = usePages();
const route = useRoute()
const user = useDirectusUser()
const randomIndex = useRandomIndex()

const openGroups = reactive({
    advocacy: false,
    publications: false,
    about: false
})

const { data } = useAsyncData('homepage', () => getHomepage());

const headerWhite = computed(() => {
    if (!data.value) return false
    const index = Math.floor(randomIndex.value * data.value?.page.splash_images.length);
    if (data.value?.page.splash_images[index]) return route.name === 'index' && data.value?.page.splash_images[index].white_menu
    else return false
});

const hideTrigger = ref<HTMLDivElement>()
const compactMenu = ref(false)
const overlayMenu = ref(false)

const { y } = useWindowScroll()
const { width: windowWidth } = useWindowSize()
useIntersectionObserver(hideTrigger,
    ([entry]) => {
        if (entry && !entry.isIntersecting) {
            compactMenu.value = true
            closeMenu()
        }
        else if (entry && entry.isIntersecting) {
            compactMenu.value = false
            if (windowWidth.value > 1200) overlayMenu.value = false
        }
    }, { rootMargin: '0px 0px 0px 0px', threshold: 0.01 }
)

const toggleGroup = (group: 'advocacy' | 'publications' | 'about') => {
    if (group != 'about') openGroups.about = false
    if (group != 'publications') openGroups.publications = false
    if (group != 'advocacy') openGroups.advocacy = false
    openGroups[group] = !openGroups[group]
}
const closeMenu = () => {
    openGroups.advocacy = false
    openGroups.publications = false
    openGroups.about = false

    if (windowWidth.value <= 1200) {
        overlayMenu.value = false
    }
}

const openFullMenu = () => {
    if (windowWidth.value > 1200) {
        compactMenu.value = false
        overlayMenu.value = true
    } else {
        if (overlayMenu.value === true) {
            openGroups.about = false
            openGroups.publications = false
            openGroups.advocacy = false
            overlayMenu.value = false
        } else overlayMenu.value = true
    }
}
watch(y, () => {
    if (overlayMenu.value === true && windowWidth.value > 1200) {
        compactMenu.value = true
        overlayMenu.value = false
        closeMenu()
    }
})
</script>

<style lang="scss" scoped>
.hideTrigger {
    position: absolute;
    top: 27px;
    width: 12px;
}

header {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--size-margin-page);

    pointer-events: none;

    .logoZone {
        display: flex;
        align-items: flex-start;
        gap: 24px;

        color: var(--color-primary);

        .symbol {
            display: block;
            padding: var(--size-margin-page) 0 0 var(--size-margin-page);

            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

            svg {
                path {
                    fill: var(--color-primary);
                }
            }
        }

        .column {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-top: 21px;
            width: 100px;

            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

            &.italic {
                font-style: italic;
            }
        }

        .sep {
            height: 27px;
            border-right: 0.5px solid var(--color-primary);
            transition: border-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
        }
    }

    .navCont {
        display: flex;
        justify-content: space-between;

        nav {
            display: flex;
            align-items: flex-start;
            gap: 24px;

            color: var(--color-primary);

            .column {
                display: flex;
                flex-direction: column;
                gap: 4px;
                padding-top: 21px;
                width: 105px;

                transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                pointer-events: all;

                .pageGroup {

                    .pageGroupTitle {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        cursor: pointer;

                        svg {
                            display: block;
                            margin-bottom: 2px;
                            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                            &.open {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                    .groupNavCont {
                        width: 200px;

                        .groupNav {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            padding: 20px 0;

                            a {
                                color: var(--color-text);
                                text-wrap: nowrap;
                            }
                        }
                    }
                }

                &.openAdvocacy {
                    transform: translate3d(0, calc(var(--text-line-header) * 2 + 8px), 0);
                }

                &.openAbout {
                    transform: translate3d(0, calc(var(--text-line-header) + 4px), 0);
                }
            }

            .sep {
                height: calc((var(--text-line-header) * 5) + 12px + 40px + (var(--text-line-body) * 6) + 40px + 21px);
                border-right: 0.5px solid var(--color-primary);

                transform: translate3d(0, calc(27px - 100%), 0);
                transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), border-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                margin-bottom: 41px;

                &.openAdvocacy {
                    transform: translate3d(0, 0, 0);
                }

                &.openPublications {
                    transform: translate3d(0, calc(0px - (var(--text-line-body) * 2) - 40px - (var(--text-line-header) * 2) - 4px), 0);
                }

                &.openAbout {
                    transform: translate3d(0, calc(0px - (var(--text-line-body) * 3) - 16px - (var(--text-line-header) * 1) - 12px), 0);
                }
            }
        }

        .rightMenu {
            display: flex;
            gap: 24px;
            padding: 21px var(--size-margin-page) 0 0;

            .rightMenuLink {
                transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                a {
                    pointer-events: auto;
                }
            }

            .hamburgerCont {
                position: absolute;
                padding: 19px var(--size-margin-page);
                top: 0;
                right: 0;

                display: flex;
                flex-direction: column;
                gap: 2px;
                cursor: pointer;
                pointer-events: auto;

                transform: translate3d(0, -100px, 0);
                transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), background-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                div {
                    width: 32px;
                    height: 4px;
                    background-color: var(--color-primary);

                    transform: translate3d(0, 0, 0);
                    transition: transform 150ms 0ms cubic-bezier(.4, 0, .2, 1), background-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                }
            }
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: calc((var(--text-line-header) * 5) + 12px + 40px + (var(--text-line-body) * 6) + 40px + 21px + 40px);
        background-color: white;
        border-bottom: 0.5px solid white;

        transform: translate3d(0, -100%, 0);
        opacity: 0;
        transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), opacity 300ms 0ms cubic-bezier(.4, 0, .2, 1), border-color 100ms 0ms cubic-bezier(.4, 0, .2, 1);

        pointer-events: all;

        &.openOverlay {
            transform: translate3d(0, calc(92px - 100%), 0);
            opacity: 1;
            border-color: var(--color-primary);
        }

        &.openAdvocacy {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), border-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
            border-color: var(--color-primary);
        }

        &.openPublications {
            transform: translate3d(0, calc(0px - (var(--text-line-body) * 2) - 40px - (var(--text-line-header) * 2) - 4px), 0);
            opacity: 1;
            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), border-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
            border-color: var(--color-primary);
        }

        &.openAbout {
            transform: translate3d(0, calc(0px - (var(--text-line-body) * 3) - 16px - (var(--text-line-header) * 1) - 12px), 0);
            opacity: 1;
            transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1), border-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
            border-color: var(--color-primary);
        }
    }

    a {
        color: var(--color-primary);
        text-decoration: none;
        pointer-events: all;
    }

    &.white:not(.openOverlay) {
        .logoZone {
            color: white;

            .symbol {
                svg path {
                    fill: white;
                }
            }

            .sep {
                border-color: white;
            }
        }

        .navCont {
            nav {
                color: white;
            }

            .sep {
                border-color: white;
            }

            .rightMenu {
                .hamburgerCont div {
                    background-color: white
                }
            }
        }

        a {
            color: white;
        }
    }

    &.compact {

        .logoZone .sep {
            border-color: var(--color-primary) !important;
        }

        .navCont .sep {
            border-color: var(--color-primary) !important;
        }

        .logoZone .symbol,
        .logoZone .column,
        .navCont .column,
        .rightMenu .rightMenuLink {
            transform: translate3d(0, -100px, 0);
        }

        .rightMenu .hamburgerCont {
            transform: translate3d(0, 0, 0);

            div {
                background-color: var(--color-primary) !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    header {
        overflow: unset;

        .navCont {
            position: absolute;
            left: 0;
            right: 0;

            display: block;

            height: 0px;
            transition: height 0ms 300ms linear;
            overflow: hidden;

            nav {

                transform: translate3d(0, -100vh, 0);
                transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                display: flex;
                flex-direction: column;
                gap: 20px;

                padding: 140px var(--size-margin-page) 38px var(--size-margin-page);

                .sep {
                    display: none;
                }

                .column {
                    padding-top: 0;
                    gap: 20px;
                    width: 145px;

                    .headerText {
                        font-size: 16px;
                        line-height: 19px;
                    }

                    .pageGroup {
                        .pageGroupTitle {
                            svg {
                                width: 8px;
                                height: auto;
                                margin-bottom: 5px;
                            }
                        }
                    }

                    .groupNav {
                        font-size: var(--text-size-bodyl);
                        line-height: var(--text-line-bodyl);
                    }

                    &.openAdvocacy {
                        transform: translate3d(0, 0, 0);
                    }

                    &.openAbout {
                        transform: translate3d(0, 0, 0);
                    }
                }
            }

            .rightMenu {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 20px;

                padding: 40px var(--size-margin-page);

                .rightMenuLink {
                    transform: translate3d(0, -100vh, 0);
                    transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                    display: block;
                    font-size: 16px;
                    line-height: 19px;
                }

                .hamburgerCont {
                    position: fixed;
                    transform: translate3d(0, 0, 0);
                }

                &:before {
                    content: '';
                    width: 28px;
                    height: 0.5px;
                    background-color: var(--color-primary);
                    position: absolute;
                    top: -1px;
                    left: var(--size-margin-page);

                    transform: translate3d(0, -100vh, 0);
                    transition: transform 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                }
            }
        }

        .background {
            position: fixed;
            height: auto;
            bottom: 0;

            &.openOverlay,
            &.openAdvocacy,
            &.openPublications,
            &.openAbout {

                transform: translate3d(0, 0, 0);
            }
        }

        &.compact {

            .navCont .column {
                transform: translate3d(0, 0, 0);
            }

            .rightMenu .rightMenuLink {
                transform: translate3d(0, -100vh, 0);
            }

            .logoZone .symbol {
                transform: translate3d(0, -100px, 0)
            }

            .logoZone .column {
                transform: translate3d(0, -100px, 0);
            }
        }

        &.openOverlay {
            .logoZone .symbol {
                transform: translate3d(0, 0, 0)
            }

            .logoZone .column {
                transform: translate3d(0, 0, 0);
            }

            .navCont {
                height: 100vh;
                transition: height 0ms 0ms linear;

                nav {
                    transform: translate3d(0, 0, 0);
                }

                .rightMenu {
                    .rightMenuLink {
                        transform: translate3d(0, 0, 0);
                    }

                    &:before {
                        transform: translate3d(0, 0, 0);
                    }

                    .hamburgerCont {
                        div:first-of-type {
                            transform: translate3d(0, 6px, 0);
                        }

                        div:last-of-type {
                            transform: translate3d(0, -6px, 0);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    header {
        overflow: unset;

        .logoZone {
            .symbol {
                svg {
                    height: 32px;
                    width: auto;
                }
            }

            .column {
                padding-top: 18px;
                transform: scale3d(0.70, 0.70, 1);
                transform-origin: top left;
                gap: 2px;

                &.italic {
                    display: none;
                }
            }

            .sep {
                display: none;
            }
        }

        .navCont {
            nav {
                padding-top: 100px;
            }
        }

        &.compact {
            .logoZone .column {
                transform: translate3d(0, -100px, 0) scale3d(0.70, 0.70, 1);
            }
        }

        &.openOverlay {
            .logoZone .column {
                transform: translate3d(0, 0, 0) scale3d(0.70, 0.70, 1);
            }

        }

    }
}
</style>