import { injectDataIntoContent } from "directus-extension-flexible-editor/content";

export const usePages = () => {

    const { getSingletonItem, getItems } = useDirectusItems();

    const getHomepage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'page_homepage',
            params: {
                fields: [
                    'cover.id',

                    'title',
                    'introduction',

                    'highlights.collection',
                    'highlights.item:articles.id',
                    'highlights.item:articles.cover.id',
                    'highlights.item:articles.cover.width',
                    'highlights.item:articles.cover.height',
                    'highlights.item:articles.cover.title',
                    'highlights.item:articles.cover.blurhash',
                    'highlights.item:articles.title',
                    'highlights.item:articles.article_date',
                    'highlights.item:articles.summary',
                    'highlights.item:articles.url',

                    'splash_images.cover.id',
                    'splash_images.cover.width',
                    'splash_images.cover.height',
                    'splash_images.cover.title',
                    'splash_images.cover.description',
                    'splash_images.cover.light_caption',
                    'splash_images.cover.blurhash',
                    'splash_images.white_menu',
                ]
            }
        })
        const fetchThemes = getItems<any>({
            collection: 'themes',
            params: {
                limit: -1,
                fields: [
                    'title',
                    'summary',
                    'url',
                ]
            }
        })
        const fetchArticles = getItems<any>({
            collection: 'articles',
            params: {
                limit: 3,
                fields: [
                    'id',
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.blurhash',
                    'title',
                    'article_date',
                    'summary',
                    'url',
                    'show_news',
                    'show_industry',
                    'types.types_id.label',
                    'themes.themes_id.title'
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: 'published'
                            }
                        }
                    ],
                },
                sort: '-article_date'
            }
        })
        const fetchNextConeference = getItems<any>({
            collection: 'conferences',
            params: {
                limit: 6,
                fields: [
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.blurhash',
                    'title',
                    'topic',
                    'summary',
                    'date_start',
                    'date_end',
                    'location',
                    'url'
                ],
                filter: { status: { _eq: 'published' } },
                sort: '-date_start'
            }
        })

        const [page, themes, articles, conferences] = await Promise.all([fetchPage, fetchThemes, fetchArticles, fetchNextConeference])
        return {
            page,
            themes,
            articles,
            conference: conferences ? conferences[0] : null,
            conferences: conferences ? conferences.slice(1, 6) : null
        }
    }

    const getPrivacyPolicyPage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'policies',
            params: {
                fields: [
                    'privacy_policy'
                ]
            }
        })


        const [page] = await Promise.all([fetchPage])
        return {
            page
        }
    }

    const getLegalNoticePage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'policies',
            params: {
                fields: [
                    'legal_notice'
                ]
            }
        })


        const [page] = await Promise.all([fetchPage])
        return {
            page
        }
    }

    const getWhoWeArePage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'page_who_we_are',
            params: {
                fields: [
                    'cover.id',

                    'title',
                    'summary',

                    'splash_images.directus_files_id.id',
                    'splash_images.directus_files_id.width',
                    'splash_images.directus_files_id.height',
                    'splash_images.directus_files_id.title',
                    'splash_images.directus_files_id.description',
                    'splash_images.directus_files_id.light_caption',
                    'splash_images.directus_files_id.blurhash',

                    'introduction',
                    'downloads.directus_files_id.id',
                    'downloads.directus_files_id.title',

                    'messages.title',
                    'messages.message',
                    'messages.name',
                    'messages.role',
                    'messages.avatar.id',
                    'messages.avatar.width',
                    'messages.avatar.height',
                    'messages.avatar.blurhash',

                    'board_introduction',
                    'board_members.avatar.id',
                    'board_members.avatar.width',
                    'board_members.avatar.height',
                    'board_members.avatar.blurhash',
                    'board_members.name',
                    'board_members.role_eic',
                    'board_members.role_external',
                    'board_members.country',
                    'board_members.link_linkedin',
                    'board_members.row',

                    'member_federations_introduction',

                    'secretariat_members.avatar.id',
                    'secretariat_members.avatar.width',
                    'secretariat_members.avatar.height',
                    'secretariat_members.avatar.blurhash',
                    'secretariat_members.name',
                    'secretariat_members.role_eic',
                    'secretariat_members.role_external',
                    'secretariat_members.country',
                    'secretariat_members.link_linkedin',

                    'associated_companies_introduction',
                    'associated_company_members.name',
                    'associated_company_members.logo.id',
                    'associated_company_members.link',
                ]
            }
        })
        const fetchFederations = getItems<any>({
            collection: 'members_federations',
            params: {
                limit: -1,
                fields: [
                    'country',
                    'url',
                    'company_name',
                    'company_logo.id',
                    'company_logo.width',
                    'company_logo.height',
                    'company_logo.blurhash',
                ],
                sort: ['-company_name']
            }
        })

        const data = await Promise.all([fetchPage, fetchFederations])
        return {
            page: data[0],
            federations: data[1]
        }
    }

    const getHistoryPage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'page_history',
            params: {
                fields: [
                    'cover.id',

                    'title',
                    'summary',
                    'introduction',
                    'downloads.directus_files_id.id',
                    'downloads.directus_files_id.title',

                    'key_dates',
                ]
            }
        })

        const data = await Promise.all([fetchPage])
        return {
            page: data[0]
        }
    }

    const getPartnersPage = async () => {
        const fetchPartners = getItems<any>({
            collection: 'partners',
            params: {
                fields: [
                    'name',
                    'logo.id',
                    'logo.width',
                    'logo.height',
                    'logo.blurhash',
                    'description',
                    'contacts'
                ]
            }
        })

        const data = await Promise.all([fetchPartners])
        return {
            partners: data[0]
        }
    }

    const getThemePage = async (theme: any) => {
        const fetchPage = getItems<any>({
            collection: 'themes',
            params: {
                fields: [
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.description',
                    'cover.light_caption',
                    'cover.blurhash',

                    'title',
                    'introduction',
                    'url',

                    'downloads.directus_files_id.id',
                    'downloads.directus_files_id.title',

                    'articles.articles_id.id',
                    'articles.articles_id.cover.id',
                    'articles.articles_id.cover.width',
                    'articles.articles_id.cover.height',
                    'articles.articles_id.cover.title',
                    'articles.articles_id.cover.blurhash',
                    'articles.articles_id.title',
                    'articles.articles_id.article_date',
                    'articles.articles_id.summary',
                    'articles.articles_id.url',
                    'articles.articles_id.show_news',
                    'articles.articles_id.show_industry',
                    'articles.articles_id.types.types_id.label',

                    'bottom_zone',
                    'bottom_downloads.directus_files_id.id',
                    'bottom_downloads.directus_files_id.title',
                ],
                filter: {
                    url: {
                        _eq: theme
                    }
                },
                deep: {
                    articles: {
                        _filter: {
                            articles_id: {
                                status: {
                                    _eq: 'published'
                                }
                            }
                        },
                        _sort: '-articles_id.article_date',
                    }
                }
            }
        })

        const data = await Promise.all([fetchPage])
        return {
            page: data[0] ? data[0][0] : null
        }
    }

    const getNewsPage = async (page = 1, theme?: string | null, type?: string | null) => {
        const articleFilter = <any>{
            _and: [
                {
                    status: {
                        _eq: 'published'
                    }
                },
                {
                    show_news: {
                        _eq: true
                    }
                },
            ]
        }
        if (theme) articleFilter._and.push({ themes: { themes_id: { url: { _eq: theme } } } })
        if (type) articleFilter._and.push({ types: { types_id: { label: { _eq: type } } } })

        const fetchArticles = getItems<any>({
            collection: 'articles',
            params: {
                limit: 8,
                page: page,
                fields: [
                    'id',
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.blurhash',
                    'title',
                    'article_date',
                    'summary',
                    'url',

                    'themes.themes_id.title',
                    'types.types_id.label',
                ],
                filter: articleFilter,
                meta: 'filter_count',
                sort: '-article_date'
            }
        })
        const fetchThemes = getItems<any>({
            collection: 'themes',
            params: {
                limit: -1,
                fields: [
                    'title',
                    'url'
                ],
                filter: {
                    articles: {
                        _nnull: true
                    }
                }
            }
        })
        const fetchTypes = getItems<any>({
            collection: 'types',
            params: {
                limit: -1,
                fields: [
                    'label'
                ],
                filter: {
                    articles: {
                        _nnull: true
                    }
                }
            }
        })

        const [articles, themes, types] = await Promise.all([fetchArticles, fetchThemes, fetchTypes])
        return {
            articles,
            themes,
            types
        }
    }

    const getIndustryPage = async (page = 1) => {
        const fetchArticles = getSingletonItem<any>({
            collection: 'page_industry',
            params: {
                fields: [
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.description',
                    'cover.light_caption',
                    'cover.blurhash',

                    'title',
                    'summary',
                    'introduction',

                    'themes.themes_id.title',
                    'types.types_id.label',
                ],
            }
        })
        const fetchPage = getItems<any>({
            collection: 'articles',
            params: {
                limit: 8,
                page: page,
                fields: [
                    'id',
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.blurhash',
                    'title',
                    'article_date',
                    'summary',
                    'url'
                ],
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: 'published'
                            }
                        },
                        {
                            show_industry: {
                                _eq: true
                            }
                        }
                    ]
                },
                meta: 'filter_count',
                sort: '-article_date'
            }
        })

        const data = await Promise.all([fetchPage, fetchArticles])
        return {
            page: data[1],
            articles: data[0]
        }
    }

    const getNews = async (slug: any) => {
        const fetchPage = getItems<any>({
            collection: 'articles',
            params: {
                fields: [
                    'id',

                    'title',
                    'article_date',

                    'show_news',
                    'show_industry',
                    'themes.themes_id.url',
                    'themes.themes_id.title',
                    'types.types_id.label',
                    'tags',

                    'body',
                    'body_nodes.id',
                    'body_nodes.item:articles_nodes_image.image.id',
                    'body_nodes.item:articles_nodes_image.image.width',
                    'body_nodes.item:articles_nodes_image.image.height',
                    'body_nodes.item:articles_nodes_image.image.title',
                    'body_nodes.item:articles_nodes_image.image.description',
                    'body_nodes.item:articles_nodes_image.image.light_caption',
                    'body_nodes.item:articles_nodes_image.image.blurhash',
                    'body_nodes.item:articles_nodes_image.caption',

                    'downloads.directus_files_id.id',
                    'downloads.directus_files_id.title',

                ],
                filter: {
                    url: {
                        _eq: slug
                    }
                },
            }
        })

        const data = await Promise.all([fetchPage])

        if (data[0] && data[0][0]) {
            injectDataIntoContent(data[0][0].body_nodes, data[0][0].body)
            delete data[0][0].body_nodes
        }

        return {
            page: data[0] ? data[0][0] : null
        }
    }

    const getContactsPage = async () => {
        const fetchPage = getSingletonItem<any>({
            collection: 'page_contacts',
            params: {
                fields: [
                    'cover.id',
                    'cover.width',
                    'cover.height',
                    'cover.title',
                    'cover.description',
                    'cover.light_caption',
                    'cover.blurhash',

                    'title',
                    'body',
                ]
            }
        })

        const data = await Promise.all([fetchPage])
        return {
            page: data[0]
        }
    }

    const getFederationPage = async (slug: any) => {
        const fetchPage = getItems<any>({
            collection: 'members_federations',
            params: {
                filter: {
                    url: {
                        _eq: slug,
                    }
                },
                fields: [
                    'country',
                    'company_name',
                    'company_contacts',
                    'company_logo.id',
                    'company_logo.width',
                    'company_logo.height',
                    'company_logo.blurhash',

                    'representative.avatar.id',
                    'representative.avatar.width',
                    'representative.avatar.height',
                    'representative.avatar.blurhash',
                    'representative.name',
                    'representative.role_eic',
                    'representative.role_external',
                    'representative.country',
                    'representative.link_linkedin',

                    'companies.logo.id',
                    'companies.logo.width',
                    'companies.logo.height',
                    'companies.logo.blurhash',
                    'companies.name',
                    'companies.link',
                    'companies.country'
                ]
            }
        });


        const [data] = await Promise.all([fetchPage])
        return {
            page: data[0]
        }
    }

    return {
        getThemePage,
        getNewsPage,
        getNews,

        getHomepage,
        getWhoWeArePage,
        getHistoryPage,
        getIndustryPage,
        getPartnersPage,

        getContactsPage,

        getFederationPage,

        getPrivacyPolicyPage,
        getLegalNoticePage
    }
}