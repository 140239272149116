
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93o4HaCMHityMeta } from "/home/bun/app/app/pages/about/federations/[slug].vue?macro=true";
import { default as historyFiDqqGZ6l2Meta } from "/home/bun/app/app/pages/about/history.vue?macro=true";
import { default as partnersrKIYRM8pvGMeta } from "/home/bun/app/app/pages/about/partners.vue?macro=true";
import { default as who_45we_45are8riCYO6ygqMeta } from "/home/bun/app/app/pages/about/who-we-are.vue?macro=true";
import { default as _91theme_93hUd35GYEvbMeta } from "/home/bun/app/app/pages/advocacy/[theme].vue?macro=true";
import { default as conference6DP5AwYw7MMeta } from "/home/bun/app/app/pages/checkout/conference.vue?macro=true";
import { default as successaJbxTBeDdrMeta } from "/home/bun/app/app/pages/checkout/success.vue?macro=true";
import { default as accommodationCMKfootI6kMeta } from "/home/bun/app/app/pages/conferences/[slug]/accommodation.vue?macro=true";
import { default as indexhRBh8gfnGIMeta } from "/home/bun/app/app/pages/conferences/[slug]/index.vue?macro=true";
import { default as linksBhlEV1M06UMeta } from "/home/bun/app/app/pages/conferences/[slug]/links.vue?macro=true";
import { default as programmeICLhQkesdXMeta } from "/home/bun/app/app/pages/conferences/[slug]/programme.vue?macro=true";
import { default as registrationja16Qq35GrMeta } from "/home/bun/app/app/pages/conferences/[slug]/registration.vue?macro=true";
import { default as speakersg28cVsoeC6Meta } from "/home/bun/app/app/pages/conferences/[slug]/speakers.vue?macro=true";
import { default as workshopwTzJOP3DrfMeta } from "/home/bun/app/app/pages/conferences/[slug]/workshop.vue?macro=true";
import { default as _91slug_93QWQWVcNof6Meta } from "/home/bun/app/app/pages/conferences/[slug].vue?macro=true";
import { default as indexhTJPddVbahMeta } from "/home/bun/app/app/pages/conferences/index.vue?macro=true";
import { default as indexuHLi6KVFGnMeta } from "/home/bun/app/app/pages/contacts/index.vue?macro=true";
import { default as indexdr4eZNytoiMeta } from "/home/bun/app/app/pages/index.vue?macro=true";
import { default as circular_45lettersGpkcKAaGZAMeta } from "/home/bun/app/app/pages/members/circular-letters.vue?macro=true";
import { default as _91slug_93El9FrEhMU1Meta } from "/home/bun/app/app/pages/members/events/[slug].vue?macro=true";
import { default as indexL3pnAE7NeeMeta } from "/home/bun/app/app/pages/members/index.vue?macro=true";
import { default as forgotYhzfKZ9feTMeta } from "/home/bun/app/app/pages/members/login/forgot.vue?macro=true";
import { default as indexakpsPm7IxHMeta } from "/home/bun/app/app/pages/members/login/index.vue?macro=true";
import { default as resetqzf94ZfpSXMeta } from "/home/bun/app/app/pages/members/login/reset.vue?macro=true";
import { default as logoutI3Vu7ymNQqMeta } from "/home/bun/app/app/pages/members/logout.vue?macro=true";
import { default as profilejxCVWhK8xPMeta } from "/home/bun/app/app/pages/members/profile.vue?macro=true";
import { default as indexoG66ETwFdpMeta } from "/home/bun/app/app/pages/members/working-groups/[slug]/index.vue?macro=true";
import { default as meetings9TdlQAcdSEMeta } from "/home/bun/app/app/pages/members/working-groups/[slug]/meetings.vue?macro=true";
import { default as _91slug_93GsYcS1QbtIMeta } from "/home/bun/app/app/pages/members/working-groups/[slug].vue?macro=true";
import { default as _91slug_932DyvxPI9o7Meta } from "/home/bun/app/app/pages/news/[slug].vue?macro=true";
import { default as index5bU50TcsDyMeta } from "/home/bun/app/app/pages/news/index.vue?macro=true";
import { default as _91page_93Cn0RcJ80ySMeta } from "/home/bun/app/app/pages/news/p/[page].vue?macro=true";
import { default as legal_45notice3TkUtPvVYeMeta } from "/home/bun/app/app/pages/policies/legal-notice.vue?macro=true";
import { default as privacy_45policyhyR1SlbZsZMeta } from "/home/bun/app/app/pages/policies/privacy-policy.vue?macro=true";
import { default as _91slug_936fT3N6boduMeta } from "/home/bun/app/app/pages/publications/annual-reports/[slug].vue?macro=true";
import { default as indexKYuKL6zsKqMeta } from "/home/bun/app/app/pages/publications/annual-reports/index.vue?macro=true";
import { default as _91slug_93UBRJMijzJMMeta } from "/home/bun/app/app/pages/publications/bookshop/[slug].vue?macro=true";
import { default as indexGK9bfBkrZ3Meta } from "/home/bun/app/app/pages/publications/bookshop/index.vue?macro=true";
import { default as indexBDbm6cDpywMeta } from "/home/bun/app/app/pages/publications/position-papers/index.vue?macro=true";
import { default as _91page_93wyYX8WHET4Meta } from "/home/bun/app/app/pages/publications/position-papers/p/[page].vue?macro=true";
import { default as statistics002qSLhQ0WMeta } from "/home/bun/app/app/pages/publications/statistics.vue?macro=true";
import { default as searchXV8Z2nVH48Meta } from "/home/bun/app/app/pages/search.vue?macro=true";
export default [
  {
    name: "about-federations-slug",
    path: "/about/federations/:slug()",
    component: () => import("/home/bun/app/app/pages/about/federations/[slug].vue")
  },
  {
    name: "about-history",
    path: "/about/history",
    component: () => import("/home/bun/app/app/pages/about/history.vue")
  },
  {
    name: "about-partners",
    path: "/about/partners",
    component: () => import("/home/bun/app/app/pages/about/partners.vue")
  },
  {
    name: "about-who-we-are",
    path: "/about/who-we-are",
    component: () => import("/home/bun/app/app/pages/about/who-we-are.vue")
  },
  {
    name: "advocacy-theme",
    path: "/advocacy/:theme()",
    component: () => import("/home/bun/app/app/pages/advocacy/[theme].vue")
  },
  {
    name: "checkout-conference",
    path: "/checkout/conference",
    meta: conference6DP5AwYw7MMeta || {},
    component: () => import("/home/bun/app/app/pages/checkout/conference.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    component: () => import("/home/bun/app/app/pages/checkout/success.vue")
  },
  {
    name: _91slug_93QWQWVcNof6Meta?.name,
    path: "/conferences/:slug()",
    component: () => import("/home/bun/app/app/pages/conferences/[slug].vue"),
    children: [
  {
    name: "conferences-slug-accommodation",
    path: "accommodation",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/accommodation.vue")
  },
  {
    name: "conferences-slug",
    path: "",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/index.vue")
  },
  {
    name: "conferences-slug-links",
    path: "links",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/links.vue")
  },
  {
    name: "conferences-slug-programme",
    path: "programme",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/programme.vue")
  },
  {
    name: "conferences-slug-registration",
    path: "registration",
    meta: registrationja16Qq35GrMeta || {},
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/registration.vue")
  },
  {
    name: "conferences-slug-speakers",
    path: "speakers",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/speakers.vue")
  },
  {
    name: "conferences-slug-workshop",
    path: "workshop",
    component: () => import("/home/bun/app/app/pages/conferences/[slug]/workshop.vue")
  }
]
  },
  {
    name: "conferences",
    path: "/conferences",
    component: () => import("/home/bun/app/app/pages/conferences/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/home/bun/app/app/pages/contacts/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/bun/app/app/pages/index.vue")
  },
  {
    name: "members-circular-letters",
    path: "/members/circular-letters",
    meta: circular_45lettersGpkcKAaGZAMeta || {},
    component: () => import("/home/bun/app/app/pages/members/circular-letters.vue")
  },
  {
    name: "members-events-slug",
    path: "/members/events/:slug()",
    meta: _91slug_93El9FrEhMU1Meta || {},
    component: () => import("/home/bun/app/app/pages/members/events/[slug].vue")
  },
  {
    name: "members",
    path: "/members",
    meta: indexL3pnAE7NeeMeta || {},
    component: () => import("/home/bun/app/app/pages/members/index.vue")
  },
  {
    name: "members-login-forgot",
    path: "/members/login/forgot",
    meta: forgotYhzfKZ9feTMeta || {},
    component: () => import("/home/bun/app/app/pages/members/login/forgot.vue")
  },
  {
    name: "members-login",
    path: "/members/login",
    meta: indexakpsPm7IxHMeta || {},
    component: () => import("/home/bun/app/app/pages/members/login/index.vue")
  },
  {
    name: "members-login-reset",
    path: "/members/login/reset",
    meta: resetqzf94ZfpSXMeta || {},
    component: () => import("/home/bun/app/app/pages/members/login/reset.vue")
  },
  {
    name: "members-logout",
    path: "/members/logout",
    component: () => import("/home/bun/app/app/pages/members/logout.vue")
  },
  {
    name: "members-profile",
    path: "/members/profile",
    meta: profilejxCVWhK8xPMeta || {},
    component: () => import("/home/bun/app/app/pages/members/profile.vue")
  },
  {
    name: _91slug_93GsYcS1QbtIMeta?.name,
    path: "/members/working-groups/:slug()",
    meta: _91slug_93GsYcS1QbtIMeta || {},
    component: () => import("/home/bun/app/app/pages/members/working-groups/[slug].vue"),
    children: [
  {
    name: "members-working-groups-slug",
    path: "",
    component: () => import("/home/bun/app/app/pages/members/working-groups/[slug]/index.vue")
  },
  {
    name: "members-working-groups-slug-meetings",
    path: "meetings",
    component: () => import("/home/bun/app/app/pages/members/working-groups/[slug]/meetings.vue")
  }
]
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/home/bun/app/app/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/bun/app/app/pages/news/index.vue")
  },
  {
    name: "news-p-page",
    path: "/news/p/:page()",
    component: () => import("/home/bun/app/app/pages/news/p/[page].vue")
  },
  {
    name: "policies-legal-notice",
    path: "/policies/legal-notice",
    component: () => import("/home/bun/app/app/pages/policies/legal-notice.vue")
  },
  {
    name: "policies-privacy-policy",
    path: "/policies/privacy-policy",
    component: () => import("/home/bun/app/app/pages/policies/privacy-policy.vue")
  },
  {
    name: "publications-annual-reports-slug",
    path: "/publications/annual-reports/:slug()",
    component: () => import("/home/bun/app/app/pages/publications/annual-reports/[slug].vue")
  },
  {
    name: "publications-annual-reports",
    path: "/publications/annual-reports",
    component: () => import("/home/bun/app/app/pages/publications/annual-reports/index.vue")
  },
  {
    name: "publications-bookshop-slug",
    path: "/publications/bookshop/:slug()",
    component: () => import("/home/bun/app/app/pages/publications/bookshop/[slug].vue")
  },
  {
    name: "publications-bookshop",
    path: "/publications/bookshop",
    component: () => import("/home/bun/app/app/pages/publications/bookshop/index.vue")
  },
  {
    name: "publications-position-papers",
    path: "/publications/position-papers",
    component: () => import("/home/bun/app/app/pages/publications/position-papers/index.vue")
  },
  {
    name: "publications-position-papers-p-page",
    path: "/publications/position-papers/p/:page()",
    component: () => import("/home/bun/app/app/pages/publications/position-papers/p/[page].vue")
  },
  {
    name: "publications-statistics",
    path: "/publications/statistics",
    component: () => import("/home/bun/app/app/pages/publications/statistics.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/bun/app/app/pages/search.vue")
  }
]